<template>
  <div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">Glossar</h5>
        <p class="text-sm text-body mb-0">Übersicht aller Begriffe</p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="glossary"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Suche"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-bold text-capitalize btn-primary bg-primary py-3 px-6 ms-3"
                    >Neuer Begriff</v-btn
                  >
                </template>
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">{{
                      formTitle
                    }}</span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Begriff</label
                          >
                          <v-text-field
                            v-model="editedItem.expression"
                            hide-details
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            :rules="[(v) => !!v || 'Field is required']"
                            placeholder="Begriff"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Erklärung</label
                          >
                          <html-editor
                            :value="editedItem.explanation"
                            @input="changeValueExplanation($event)"
                          ></html-editor>
                          <!-- <v-textarea
                            v-model="editedItem.explanation"
                            hide-details
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            filled
                            solo
                            :rules="[(v) => !!v || 'Field is required']"
                            placeholder="Erklärung"
                          ></v-textarea> -->
                        </v-col>
                        <v-col cols="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Erläuterung</label
                          >
                          <html-editor
                            :value="editedItem.explanationDetail"
                            @input="changeValueExplanationDetail($event)"
                          ></html-editor>
                        </v-col>
                        <v-col cols="12">
                          <label
                            class="text-xs text-typo font-weight-bolder ms-1"
                            >Beispiel</label
                          >
                          <html-editor
                            :value="editedItem.example"
                            @input="changeValueExample($event)"
                          ></html-editor>
                          <!-- <v-textarea
                            v-model="editedItem.example"
                            hide-details
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            filled
                            solo
                            :rules="[(v) => !!v || 'Field is required']"
                            placeholder="Beispiel"
                          ></v-textarea> -->
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.languageId"
                            label="Sprache"
                            :items="languages"
                            item-text="title"
                            item-value="id"
                            hide-details
                            flat
                            filled
                            solo
                            :rules="[(v) => !!v || 'Field is required']"
                            :menu-props="{
                              nudgeBottom: 15 + 'px',
                              zIndex: 1000,
                              rounded: 'xl',
                            }"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.categoryId"
                            label="Kategorie"
                            :items="categories"
                            item-text="title"
                            item-value="id"
                            hide-details
                            flat
                            solo
                            outlined
                            :rules="[(v) => !!v || 'Field is required']"
                            :menu-props="{
                              nudgeBottom: 15 + 'px',
                              zIndex: 1000,
                              rounded: 'xl',
                            }"
                          >
                          </v-autocomplete>
                          <!-- <v-select
                            v-model="editedItem.categoryId"
                            label="Kategorie"
                            :items="categories"
                            item-value="id"
                            item-text="title"
                          ></v-select> -->
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                      >Abbrechen</v-btn
                    >

                    <v-btn
                      @click="save"
                      :disabled="
                        !editedItem.categoryId ||
                        !editedItem.languageId ||
                        !editedItem.expression ||
                        !editedItem.explanation
                      "
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-success py-3 px-6"
                      >Speichern</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title
                    class="pt-0 px-2 text-h5 text-typo justify-center"
                    >Möchten Sie diesen Begriff wirklich löschen?</v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeDelete"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
                      >Abbrechen</v-btn
                    >

                    <v-btn
                      @click="deleteItemConfirm"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-bold text-capitalize btn-ls btn-primary bg-danger py-3 px-6"
                      >Ok</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.explanation`]="{ item }">
            <div class="d-flex align-center">
              <span
                class="text-sm font-weight-normal"
                v-html="item.explanation"
              >
              </span>
            </div>
          </template>

          <template v-slot:item.expression="{ item }">
            <v-card rounded="xl">
              
                <v-card
                  class="pa-4 my-2"
                  @click="
                    editItem(item)
                  "
                >
                  <v-row class="ma-0 pa-0">
                    <div>
                      <v-avatar
                        rounded
                        width="50"
                        height="50"
                        class="pa-4"
                        style="
                          background-color: lightgray;
                          border-radius: 100px !important;
                        "
                      >
                        <div class="pt-1">
                          <v-img
                            max-width="25"
                            max-height="25"
                            :src="
                              require(`@/assets/icons/${item.category.icon}.png`)
                            "
                          ></v-img>
                        </div>
                      </v-avatar>
                    </div>
                    <div class="ml-3">
                      <p class="text-h6 ma-0 pa-0">
                        {{ item.expression }}
                      </p>
                      <p class="ma-0 pa-0 text-body">
                        {{ item.views.length }} Ansicht{{
                          item.views.length > 1 ||
                          item.views.length == 0
                            ? "en"
                            : ""
                        }}
                        • {{ item.ratings.length }} Bewertung{{
                          item.ratings.length > 1 ||
                          item.ratings.length == 0
                            ? "en"
                            : ""
                        }}
                      </p>
                    </div>
                  </v-row>
                </v-card>
              
            </v-card>
          </template>

          <template v-slot:item.age="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.age }}
            </span>
          </template>

          <template v-slot:item.salary="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.salary }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="12">ni-ruler-pencil</v-icon>
            </v-btn>

            <v-btn
              @click="deleteItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="12">ni-fat-remove text-lg</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Einträge pro Seite:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import users from "./Users";
import HtmlEditor from "./HtmlEditor.vue";

export default {
  name: "paginated-tables",
  mounted() {
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getLanguages");
    this.$store.dispatch("getCategory");
  },
  components: {
    HtmlEditor,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      users,
      search: "",
      editedIndex: -1,
      editedItem: {
        language: { languageId: 1 },
        category: {},
      },
      defaultItem: {
        language: { languageId: 1 },
        category: {},
      },
      headers: [
        {
          text: "Begriff",
          align: "start",
          cellClass: "border-bottom",
          sortable: true,
          value: "expression",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        // {
        //   text: "Erklärung",
        //   value: "explanation",
        //   sortable: true,
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },

        {
          text: "Kategorie",
          value: "category.title",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Sprache",
          value: "language.title",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Ansichten",
          value: "views.length",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  methods: {
    changeValueExplanationDetail(newVal) {
      this.editedItem.explanationDetail = newVal;
    },
    changeValueExplanation(newVal) {
      this.editedItem.explanation = newVal;
    },
    changeValueExample(newVal) {
      this.editedItem.example = newVal;
    },
    editItem(item) {
      this.editedIndex = this.glossary.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.glossary.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // this.glossary.splice(this.editedIndex, 1);
      this.closeDelete();
      this.$store.dispatch("deleteGlossary", this.editedItem.id);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.glossary[this.editedIndex], this.editedItem);
        this.$store.dispatch("editGlossary", this.editedItem);
      } else {
        this.$store.dispatch("postGlossary", this.editedItem);
      }
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data;
      } else {
        return [];
      }
    },
    languages() {
      if (this.$store.getters.languages.data) {
        return this.$store.getters.languages.data;
      } else {
        return [];
      }
    },
    categories() {
      if (this.$store.getters.categories.data) {
        return this.$store.getters.categories.data;
      } else {
        return [];
      }
    },
    formTitle() {
      return this.editedIndex == -1 ? "Neuer Begriff" : "Begriff editieren";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
